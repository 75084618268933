<template>
  <v-row>
    <v-col class="mb-4">
      <order-dialog v-if="openDialog" v-model='openDialog' :orderNumber='orderNumber' @close="closeDialog" />
      <h1 class="display-1 font-weight-bold mx-1 mb-3">Previously ordered products</h1>
      <v-data-table :items="items" item-key="sku" :headers="headers" hide-default-footer :disable-pagination=true>
        <!-- <template v-slot:[`item.updateorder`]="{ item }">
          <add-order-item :item="{
            sku: item.sku,
            unitPrice: item.unitPrice,
            minQty: item.minQty,
            multQty: item.multQty
          }" :cart="cart" />
        </template> -->
        <template v-slot:[`item.orders[0]`]="{ item }">
          {{ new Date(item.dates[0]).toLocaleDateString() }} <a href="#" @click="showOrder(item.orders[0])">({{
            item.orders[0] }})</a>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import OrderApi from '@/api/order'
import OrderDialog from '@/components/OrderDialog'
import AddOrderItem from '@/components/AddOrdersMenu.vue'

export default {
  components: {
    OrderDialog,
    AddOrderItem
  },
  data() {
    return {
      orderNumber: null,
      openDialog: false,
      items: [],
      headers: [
        {
          text: '#',
          align: 'end',
          value: 'numItems'
        },
        {
          text: 'Title',
          align: 'start',
          value: 'title'
        },
        {
          text: 'SKU',
          align: 'start',
          value: 'sku'
        },
        {
          text: 'Category',
          value: 'category'
        },
        // {
        //   text: '',
        //   value: 'updateorder'
        // }
      ]
    }
  },
  computed: mapState({
    cart() { return this.$store.getters['cart/cart'] }
  }),
  methods: {
    showOrder(orderNumber) {
      this.openDialog = true
      this.orderNumber = orderNumber
    },
    closeDialog() {
      this.openDialog = false
    },
  },
  async created() {
    await OrderApi.countUserOrderItems().then((results) => { this.items = results.data })
  }
}
</script>
