<template>
<v-card class="d-flex">
  <v-btn small style="width:50%" tile :disabled="!dirty && isInCart" color="primary" @click="addProductToCart" >
    <v-icon
      left
      dark
    >
      mdi-cart-outline
    </v-icon>
    {{isInCart ? 'Save' : 'Add'}}
  </v-btn>
  <div class="d-flex align-center mr-auto ml-auto">
    <v-icon small @click="decrement(cartItem)">
      mdi-minus
    </v-icon>
    <span class="mx-2">{{ cartItem.qty }}</span>
    <v-icon small @click="increment(cartItem)">
      mdi-plus
    </v-icon>
  </div>
</v-card>
</template>

<script>
export default {
  props: {
    item: Object,
    cart: Object
  },
  data () {
    return {
      cartItem: {
        sku: this.item.sku,
        unitPrice: this.item.unitPrice,
        qty: this.item.minQty
      },
      dirty: false
    }
  },
  created: function () {
    this.checkCart()
  },
  mounted: function () {
    this.checkCart()
  },
  computed: {
    isInCart () {
      return this.cart.items.findIndex((cartItem) => cartItem.sku === this.cartItem.sku) > -1
    }
  },
  methods: {
    // test
    checkCart () {
      this.cart.items.map((cartItem) => {
        if (this.item.sku === cartItem.sku) {
          this.cartItem = {
            sku: cartItem.sku,
            unitPrice: cartItem.unitPrice,
            qty: cartItem.qty
          }
        }
      })
    },
    decrement (item) {
      this.dirty = true
      if (item.qty > this.item.minQty) item.qty -= this.item.multQty
    },
    increment (item) {
      this.dirty = true
      item.qty += this.item.multQty
    },
    addProductToCart () {
      this.dirty = false
      this.$store.dispatch('cart/updateCartItems', [this.cartItem])
      // this.$emit('addProduct', this.cartItem)
    },
    close () {
      this.menu = false
    }
  }
}
</script>
