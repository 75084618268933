<template>
  <v-dialog v-model="value" @click:outside="close" scrollable max-width="90vw" >
    <v-container fluid :style="{padding: 0}">
    <v-card>
      <v-card-title>
        Order # {{ order.orderNumber }} {{ new Date(order.orderDate).toLocaleString()}}
      </v-card-title>
      <v-card-text>
        <v-data-table
          id="testHtml"
          :items="order.orderItems"
          item-key="orderNumber"
          :headers="headers"
          hide-default-footer
          :disable-pagination=true
          no-data-text="No items in your cart"
        >
          <template v-slot:[`item.img`]="{ item }">
            <v-img class="mx-2" max-height="50px" max-width="50px"
                :src="`${imageUrl}/100px/${getImageToDisplay(item.product.images[0])}`"
              ></v-img>
          </template>
          <template v-slot:[`item.product.unitPrice`]="{ item }">
              £{{item.product.unitPrice.toFixed(2)}}
          </template>
          <template v-slot:[`item.qty`]="{ item }">
            <div class="d-flex align-center mr-2">
              <v-icon small @click.native="decrement(item)">
                mdi-chevron-left
              </v-icon>
              <span class="mx-2">{{ item.qty }}</span>
              <v-icon small @click.native="increment(item)">
                mdi-chevron-right
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.total`]="{ item }">
              £{{(item.product.unitPrice * item.qty).toFixed(2)}}
          </template>
          <template slot="body.append">
            <tr>
              <th style="font-size: .875rem; text-align: left">Total (Net)</th>
              <th colspan="6"></th>
              <th style="font-size: .875rem; text-align: right">£{{ order.total.toFixed(2) }}</th>
              <th />
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import OrderApi from '@/api/order'
export default {
  components: {
  },
  props: {
    value: Boolean,
    orderNumber: Number
  },
  data () {
    return {
      order: null,
      imageUrl: process.env.VUE_APP_IMG_URL,
      headers: [
        { text: '', value: 'img' },
        {
          text: 'Title',
          align: 'start',
          value: 'product.title'
        },
        { text: 'Category', value: 'product.category' },
        { text: 'Product', value: 'product.variantCode' },
        { text: 'Unit Price', value: 'product.unitPrice', align: 'end' },
        { text: 'Variant', value: 'product.imageColour' },
        { text: 'Quantiy', value: 'qty' },
        { text: 'Total', value: 'total', align: 'end' }
      ]
    }
  },
  computed: mapState({
    ...mapGetters([
      'accessRole',
      'isLoggedIn'
    ])
  }),
  methods: {
    close () {
      this.$emit('close', this.currentItem)
    },
    addProductToOrder (item) {
      this.$store.dispatch('orders/updateOrderItems', [item])
    }
  },
  async created () {
    OrderApi.getUserOrder(this.orderNumber).then(resp => {
      this.order = resp.data
    })
  }
}
</script>
